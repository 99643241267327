<script setup lang="ts">
import { useQuery } from '@tanstack/vue-query';
import { type Shift } from '@/api/shifts';
import { vitalSignRecordsApi } from '@/api/vital-sign-records';

const props = defineProps<{ shift: Shift }>();

const {
  data: vitalSignRecords,
  isLoading: isLoadingVitalSignRecords,
} = useQuery({
  queryKey: ['vitalSignRecords', props.shift.id],
  queryFn: () => vitalSignRecordsApi.indexByShift(props.shift.patientId, props.shift.id),
});

const headers = [
  { title: 'Signo Vital', key: 'vitalSignName', align: 'start' },
  { title: 'Valor', key: 'formattedValue', align: 'start' },
  { title: 'Fecha y Hora', key: 'humanRecordedAt', align: 'start' },
];
</script>
<template>
  <v-data-table
    :loading="isLoadingVitalSignRecords"
    :headers="headers"
    :header-props="{ class: 'font-weight-bold' }"
    :height="(vitalSignRecords && vitalSignRecords.length > 0) ? 400 : 200"
    :items="vitalSignRecords"
    :items-per-page="-1"
    class="pa-2"
    loading-text="Cargando signos vitales..."
    no-data-text="No hay registros de signos vitales en este turno"
    hide-default-footer
  >
    <template #bottom />
  </v-data-table>
</template>
