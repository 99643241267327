import { api } from './index';

const BASE_PATH = '/api/internal/vital_sign_records';

export type VitalSignRecord = {
  id: number;
  patientId: number;
  shiftId: number;
  vitalSignId: number;
  value: string;
  recordedAt: string;
  bloodPressureRecordSystolic: number;
  bloodPressureRecordDiastolic: number;
  humanRecordedAt: string;
  formattedValue: string;
  vitalSignName: string;
}

export const vitalSignRecordsApi = {
  index(patientId: number, vitalSignId: number): Promise<VitalSignRecord[]> {
    return api({
      method: 'get',
      url: `${BASE_PATH}?patient_id=${patientId}&vital_sign_id=${vitalSignId}`,
    }).then(response => response.data.vitalSignRecords);
  },
  latestIndex(patientId: number): Promise<VitalSignRecord[]> {
    return api({
      method: 'get',
      url: `${BASE_PATH}?patient_id=${patientId}`,
    }).then(response => response.data.vitalSignRecords);
  },
  indexByShift(patientId: number, shiftId: number): Promise<VitalSignRecord[]> {
    return api({
      method: 'get',
      url: `${BASE_PATH}?patient_id=${patientId}&shift_id=${shiftId}`,
    }).then(response => response.data.vitalSignRecords);
  },
};
