import { api } from './index';

const BASE_PATH = '/api/internal/vital_signs';

export type VitalSign = {
  id: number;
  code: string;
  name: string;
  unit: string;
  icon: string;
}

export const vitalSignsApi = {
  index(): Promise<VitalSign[]> {
    return api({
      method: 'get',
      url: BASE_PATH,
    }).then(response => response.data.vitalSigns);
  },
};
