<script setup lang="ts">
import { inject, type Ref } from 'vue';
import { type Patient } from '@/api/patients';
import { type SerializedUser } from '@/api/users';
import GoBackButton from '@/components/go-back-button.vue';
import PatientServicePricesList from './patient-service-prices-list.vue';

const currentUser = inject<Ref<SerializedUser>>('currentUser');

interface Props {
  patient: Patient,
}

const props = defineProps<Props>();
</script>
<template>
  <v-container class="d-flex flex-column">
    <go-back-button path="/patients" />
    <v-card>
      <v-row class="d-flex flex-column flex-md-row">
        <v-col class="v-col-2">
          <v-avatar
            image="@assets/images/no-profile-picture.png"
            size="100"
            class="ma-4"
          />
        </v-col>
        <v-col class="v-col-8">
          <v-card-title class="font-weight-bold">
            {{ props.patient.firstName }} {{ props.patient.lastName }}
          </v-card-title>
          <v-card-text
            v-if="currentUser?.companyUsesIdCode"
            class="py-2"
          >
            Código: {{ props.patient.idCode }}
          </v-card-text>
          <v-card-text class="py-2">
            Nombre Social: {{ props.patient.nickname }}
          </v-card-text>
          <v-card-text class="py-2">
            Edad: {{ props.patient.age ? `${props.patient.age} años` : '' }}
          </v-card-text>
          <v-card-text class="py-2">
            Fecha de Nacimiento: {{ props.patient.humanBirthdate }}
          </v-card-text>
          <v-card-text class="py-2">
            Peso: {{ props.patient.weight ? `${props.patient.weight} kg` : '' }}
          </v-card-text>
          <v-card-text class="py-2">
            Dirección: {{ props.patient.address }}
          </v-card-text>
          <v-card-text class="py-2">
            Rut: {{ props.patient.dni }}
          </v-card-text>
          <v-card-text class="py-2">
            Contacto de Emergencia:
            {{ props.patient.representative.firstName }} {{ props.patient.representative.lastName }}
            - {{ props.patient.representative.phoneNumber }}
          </v-card-text>
        </v-col>
        <v-col class="v-col-2">
          <v-row class="mt-1">
            <v-btn
              icon="mdi-pencil"
              color="primary"
              size="small"
              variant="outlined"
              class="ma-4"
              :href="`/patients/${props.patient.id}/edit`"
            >
              <v-icon icon="mdi-pencil" />
              <v-tooltip
                activator="parent"
                location="top"
              >
                Editar Paciente
              </v-tooltip>
            </v-btn>
            <v-btn
              icon="mdi-calendar"
              color="primary"
              size="small"
              variant="outlined"
              class="ma-4"
              :href="`/shifts?patient_id=${props.patient.id}`"
            >
              <v-icon icon="mdi-calendar" />
              <v-tooltip
                activator="parent"
                location="top"
              >
                Ver Agenda
              </v-tooltip>
            </v-btn>
          </v-row>
          <v-row>
            <v-btn
              icon="mdi-calendar-plus"
              color="primary"
              size="small"
              variant="outlined"
              class="ma-4"
              :href="`/shifts/new?patient_id=${props.patient.id}`"
            >
              <v-icon icon="mdi-calendar-plus" />
              <v-tooltip
                activator="parent"
                location="top"
              >
                Agregar Turnos
              </v-tooltip>
            </v-btn>
            <v-dialog
              max-width="1000"
              scrollable
            >
              <template #activator="{ props: activatorProps }">
                <v-btn
                  v-if="currentUser?.companyProfessionalViewEnabled"
                  v-bind="activatorProps"
                  icon="mdi-currency-usd"
                  color="primary"
                  size="small"
                  class="ma-4"
                  variant="outlined"
                >
                  <v-icon icon="mdi-currency-usd" />
                  <v-tooltip
                    activator="parent"
                    location="top"
                  >
                    Precios de Servicios
                  </v-tooltip>
                </v-btn>
              </template>
              <template #default="{ isActive }">
                <patient-service-prices-list
                  :patient-id="props.patient.id"
                  @close="isActive.value = false"
                />
              </template>
            </v-dialog>
          </v-row>
          <v-row>
            <v-btn
              icon="mdi-file-document"
              color="primary"
              size="small"
              variant="outlined"
              class="ma-4"
              :href="`/patients/${props.patient.id}/shifts`"
            >
              <v-icon icon="mdi-file-document" />
              <v-tooltip
                activator="parent"
                location="top"
              >
                Ver Ficha Clínica
              </v-tooltip>
            </v-btn>
            <v-btn
              icon="mdi-clipboard-pulse"
              color="primary"
              size="small"
              variant="outlined"
              class="ma-4"
              :href="`/patients/${props.patient.id}/vital_signs`"
            >
              <v-icon icon="mdi-clipboard-pulse" />
              <v-tooltip
                activator="parent"
                location="top"
              >
                Ver Signos Vitales
              </v-tooltip>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-expansion-panels class="mt-4">
      <v-expansion-panel class="mt-4">
        <v-expansion-panel-title class="font-weight-bold">
          Antecedentes Médicos
        </v-expansion-panel-title>
        <v-expansion-panel-text class="py-2 text-body-2 preserve-whitespace">
          {{ props.patient.medicalHistory }}
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel class="mt-4">
        <v-expansion-panel-title class="font-weight-bold">
          Antecedentes Quirúrgicos
        </v-expansion-panel-title>
        <v-expansion-panel-text class="py-2 text-body-2 preserve-whitespace">
          {{ props.patient.surgicalHistory }}
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel class="mt-4">
        <v-expansion-panel-title class="font-weight-bold">
          Ayudas Técnicas
        </v-expansion-panel-title>
        <v-expansion-panel-text class="py-2 text-body-2 preserve-whitespace">
          {{ props.patient.technicalAids }}
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel class="mt-4">
        <v-expansion-panel-title class="font-weight-bold">
          Seguros y Convenios
        </v-expansion-panel-title>
        <v-expansion-panel-text class="py-2 text-body-2 preserve-whitespace">
          {{ props.patient.insurances }}
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel class="mt-4">
        <v-expansion-panel-title class="font-weight-bold">
          Comentarios y Observaciones
        </v-expansion-panel-title>
        <v-expansion-panel-text class="py-2 text-body-2 preserve-whitespace">
          {{ props.patient.comments }}
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<style scoped>
.preserve-whitespace {
  white-space: pre-wrap;
}
</style>
