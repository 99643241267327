<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { type Patient } from '@/api/patients';
import { vitalSignsApi, type VitalSign } from '@/api/vital-signs';
import { vitalSignRecordsApi, type VitalSignRecord } from '@/api/vital-sign-records';
import { Line } from 'vue-chartjs';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import GoBackButton from '@/components/go-back-button.vue';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const props = defineProps<{
  patient: Patient;
}>();

const showChartModal = ref(false);
const selectedVitalSign = ref<VitalSign | null>(null);

const { data: vitalSigns } = useQuery({
  queryKey: ['vitalSigns'],
  queryFn: () => vitalSignsApi.index(),
});

const { data: vitalSignRecords, refetch: refetchVitalSignRecords } = useQuery({
  queryKey: ['vitalSignRecords', selectedVitalSign.value?.id],
  queryFn: () => {
    if (!selectedVitalSign.value) throw new Error('No vital sign selected');

    return vitalSignRecordsApi.index(props.patient.id, selectedVitalSign.value.id);
  },
  enabled: !!selectedVitalSign.value,
  initialData: [],
});

watch(selectedVitalSign, () => {
  refetchVitalSignRecords();
});

const { data: latestVitalSignRecords } = useQuery({
  queryKey: ['latestVitalSignRecords'],
  queryFn: () => vitalSignRecordsApi.latestIndex(props.patient.id),
  initialData: [],
});

function getLatestVitalSignRecord(vitalSign: VitalSign) {
  const latestVitalSignRecord = latestVitalSignRecords.value.find(
    (record) => record.vitalSignId === vitalSign.id,
  );

  return latestVitalSignRecord ? latestVitalSignRecord.formattedValue : null;
}

async function openChartModal(vitalSign: VitalSign) {
  selectedVitalSign.value = vitalSign;
  showChartModal.value = true;
}

const chartData = computed(() => {
  if (!selectedVitalSign.value) return null;

  type VitalSignRecordValue = number | { systolic: number; diastolic: number };
  const values: VitalSignRecordValue[] = vitalSignRecords.value.map((record: VitalSignRecord) => {
    if (selectedVitalSign.value?.code === 'blood_pressure') {
      return {
        systolic: record.bloodPressureRecordSystolic,
        diastolic: record.bloodPressureRecordDiastolic,
      };
    }

    return parseFloat(record.value);
  });

  const dates = vitalSignRecords.value.map((record: VitalSignRecord) => record.humanRecordedAt);

  if (selectedVitalSign.value.code === 'blood_pressure') {
    return {
      labels: dates,
      datasets: [
        {
          label: 'Sistólica',
          data: values.map(record => (record as { systolic: number }).systolic),
          borderColor: '#5333ED',
          tension: 0.1,
        },
        {
          label: 'Diastólica',
          data: values.map(record => (record as { diastolic: number }).diastolic),
          borderColor: '#FF6384',
          tension: 0.1,
        },
      ],
    };
  }

  return {
    labels: dates,
    datasets: [
      {
        label: selectedVitalSign.value.name,
        data: values,
        borderColor: '#5333ED',
        tension: 0.1,
      },
    ],
  };
});

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom' as const,
    },
    title: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};
</script>
<template>
  <v-container class="v-col-12 v-col-md-8">
    <go-back-button :path="`/patients/${patient.id}`" />
    <h2 class="mt-4 mb-8 text-h5">
      Signos Vitales de Paciente {{ patient.fullName }}
    </h2>
    <v-row>
      <v-col
        v-for="vitalSign in vitalSigns"
        :key="vitalSign.id"
        cols="12"
        sm="6"
      >
        <v-card class="h-100">
          <v-card-text>
            <v-row class="align-center">
              <v-col class="d-flex align-center">
                <v-icon
                  size="24"
                  class="mr-2"
                  color="primary"
                >
                  {{ `mdi-${vitalSign.icon}` }}
                </v-icon>
                <span class="text-subtitle-1">{{ vitalSign.name }}</span>
              </v-col>
            </v-row>
            <v-row class="align-center">
              <v-col
                cols="6"
                class="text-right"
              >
                <v-row class="ml-1 align-baseline">
                  <div
                    v-if="getLatestVitalSignRecord(vitalSign)"
                    class="text-h4 text-primary"
                  >
                    {{ getLatestVitalSignRecord(vitalSign) }}
                  </div>
                  <div
                    v-else
                    class="text-subtitle-2 text-grey"
                  >
                    --
                  </div>
                  <span class="ml-1 text-caption text-grey">{{ vitalSign.unit }}</span>
                </v-row>
              </v-col>
              <v-col
                cols="6"
                class="text-right"
              >
                <v-btn
                  v-if="getLatestVitalSignRecord(vitalSign)"
                  icon
                  variant="text"
                  density="compact"
                  @click="openChartModal(vitalSign)"
                >
                  <v-icon>mdi-chart-line</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="showChartModal"
      max-width="800"
    >
      <v-card v-if="selectedVitalSign">
        <v-card-title class="d-flex justify-space-between align-center pa-4">
          <span>Historial de {{ selectedVitalSign.name }}</span>
          <v-btn
            icon
            variant="text"
            @click="showChartModal = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <Line
            v-if="chartData"
            :data="chartData"
            :options="chartOptions"
            :style="{ height: '400px' }"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
