import { api } from './index';

export type AuditUser = {
  id?: number;
  email: string;
}

export interface AuditUserForm extends AuditUser {
  password: string;
}

export type SerializedAuditUser = AuditUser;

export const auditUsersApi = {
  login(user: Partial<AuditUserForm>): Promise<SerializedAuditUser> {
    return api({
      method: 'post',
      url: '/audit_users/sign_in',
      data: { user },
    }).then(res => res.data.user);
  },
  logout() {
    return api({
      method: 'delete',
      url: '/audit_users/sign_out',
    }).then(res => res.data);
  },
  recoverPassword(email: string) {
    return api({
      method: 'post',
      url: '/audit_users/password',
      data: { user: { email } },
    }).then(res => res.data);
  },
};
