export const esLocale = {
  // Audit User Session
  auditLogin: {
    passwordRequired: 'Contraseña requerida',
    emailRequired: 'Correo electrónico requerido',
    title: 'Ingreso al Portal de Fiscalización: Iniciar sesión',
    subtitle: 'Ingresa tu correo electrónico y contraseña para entrar al portal.',
    email: 'Correo electrónico',
    password: 'Contraseña',
    submit: 'Iniciar sesión',
    forgotPassword: 'Obtener contraseña',
    emailInvalid: 'Correo electrónico no es válido',
    passwordMinLength: 'La contraseña debe tener al menos 8 caracteres',
    passwordEmailSent: 'Se ha enviado la contraseña a tu correo electrónico.',
  },
  auditRecoverPassword: {
    title: 'Ingreso al Portal de Fiscalización: Obtener contraseña',
    subtitle: 'Ingresa tu correo electrónico para recibir la contraseña de acceso.',
    email: 'Correo electrónico',
    submit: 'Enviar contraseña',
    backToLogin: 'Volver al inicio de sesión',
  },
  // Base Service Prices
  baseServicePriceForm: {
    fieldRequired: 'Campo requerido',
    fieldPositive: 'Debe ser un número mayor a 0',
    priceAlreadyExists: 'El precio para este servicio ya está definido.',
    withTaxBillTitle: '¿Con boleta de honorarios?',
    yes: 'Sí',
    no: 'No',
  },
  // Caregivers
  caregiver: {
    idCode: 'Código',
    firstName: 'Nombre',
    lastName: 'Apellido',
    email: 'Correo electrónico',
    phoneNumber: 'Número de teléfono',
    countryId: 'Nacionalidad',
    address: 'Dirección',
    dateOfBirth: 'Fecha de nacimiento',
    description: 'Experiencia y Estudios',
    dni: 'RUT',
    sex: 'Sexo',
    career: 'Especialidad',
    communeId: 'Comuna',
    comments: 'Comentarios y Observaciones',
  },
  caregiverForm: {
    fieldRequired: 'Campo requerido',
    createTitle: 'Agregar Profesional',
    updateTitle: 'Editar Profesional',
    createSubmit: 'Agregar',
    updateSubmit: 'Guardar',
    male: 'Masculino',
    female: 'Femenino',
    invalidDni: 'El RUT ingresado no es válido',
    recordNotUnique: 'Ya existe un profesional con este correo. Si necesitas ayuda, por favor contacta a soporte.',
    passwordIsSet: 'Utilizar contraseña por defecto para acceso a Kuido Care.',
  },
  caregiverDiscardForm: {
    title: 'Archivar Profesional',
    confirmation: `Estás a punto de archivar a este profesional.
      Si lo haces, este profesional dejará de aparecer en la vista de turnos.`,
    submit: 'Archivar',
  },
  caregiverUndiscardForm: {
    title: 'Desarchivar Profesional',
    confirmation: `Estás a punto de desarchivar a este profesional.
      Si lo haces, este profesional volverá a aparecer en la vista de turnos.`,
    submit: 'Desarchivar',
  },
  caregiverDocument: {
    name: 'Nombre',
    documentUrl: 'Documento',
    document: 'Documento',
  },
  caregiverDocumentForm: {
    fieldRequired: 'Campo requerido',
    createTitle: 'Agregar Documento',
    updateTitle: 'Editar Documento',
    createSubmit: 'Agregar',
    updateSubmit: 'Guardar',
    fileSizeLimit: 'El documento debe pesar máximo 30MB.',
  },
  caregiverDocumentDestroyForm: {
    title: 'Eliminar Documento',
    submit: 'Eliminar',
    confirmation: '¿Estás seguro que deseas eliminar este documento?',
  },
  // Charge Movements
  chargeMovement: {
    date: 'Fecha',
    movementType: 'Tipo de Movimiento',
    amount: 'Monto',
    description: 'Descripción',
  },
  chargeMovementForm: {
    fieldRequired: 'Campo requerido',
    createTitle: 'Agregar Movimiento',
    updateTitle: 'Editar Movimiento',
    createSubmit: 'Agregar',
    updateSubmit: 'Guardar',
    fieldPositive: 'Debe ser un número positivo',
    validDateRange: 'La fecha debe estar dentro del período seleccionado',
  },
  chargeMovementDestroyForm: {
    title: 'Eliminar Movimiento',
    submit: 'Eliminar',
    confirmation: '¿Estás seguro que deseas eliminar este movimiento?',
  },
  // Payment Movements
  paymentMovement: {
    date: 'Fecha',
    movementType: 'Tipo de Movimiento',
    amount: 'Monto',
    description: 'Descripción',
  },
  paymentMovementForm: {
    fieldRequired: 'Campo requerido',
    createTitle: 'Agregar Movimiento',
    updateTitle: 'Editar Movimiento',
    createSubmit: 'Agregar',
    updateSubmit: 'Guardar',
    fieldPositive: 'Debe ser un número positivo',
    validDateRange: 'La fecha debe estar dentro del período seleccionado',
  },
  paymentMovementDestroyForm: {
    title: 'Eliminar Movimiento',
    submit: 'Eliminar',
    confirmation: '¿Estás seguro que deseas eliminar este movimiento?',
  },
  // Patients
  patient: {
    idCode: 'Código',
    firstName: 'Nombre',
    lastName: 'Apellido',
    nickname: 'Apodo',
    address: 'Dirección',
    birthdate: 'Fecha de nacimiento',
    dni: 'RUT',
    weight: 'Peso',
    sex: 'Sexo',
    medicalHistory: 'Antecedentes Médicos',
    surgicalHistory: 'Antecedentes Quirúrgicos',
    technicalAids: 'Ayudas Técnicas',
    insurances: 'Seguros y Convenios',
    comments: 'Comentarios y Observaciones',
    representative: 'Apoderados',
    representativeFirstName: 'Nombre',
    representativeLastName: 'Apellido',
    representativeEmail: 'Correo electrónico',
    representativePhoneNumber: 'Número de teléfono',
    representativeDni: 'RUT',
  },
  patientForm: {
    fieldRequired: 'Campo requerido',
    createTitle: 'Agregar Paciente',
    updateTitle: 'Editar Paciente',
    createSubmit: 'Agregar Paciente',
    updateSubmit: 'Guardar Paciente',
    male: 'Masculino',
    female: 'Femenino',
    invalidDni: 'El RUT ingresado no es válido',
    addressMap: 'Ajustar dirección en mapa',
    representativeRecordNotUnique: `Ya existe un apoderado con este correo.
      Si necesitas ayuda, por favor contacta a soporte.`,
    atLeastOneRepresentative: 'Debes agregar al menos un apoderado.',
    emailFormat: 'El correo electrónico debe tener un formato válido.',
    locationExtended: 'Aumentar alcance para la marcación de turnos en Kuido Care.',
  },
  patientDiscardForm: {
    title: 'Archivar Paciente',
    confirmation: `Estás a punto de archivar a este paciente.
      Si lo haces, este paciente dejará de aparecer en la vista de turnos.`,
    submit: 'Archivar',
  },
  patientUndiscardForm: {
    title: 'Desarchivar Paciente',
    confirmation: `Estás a punto de desarchivar a este paciente.
      Si lo haces, este paciente volverá a aparecer en la vista de turnos.`,
    submit: 'Desarchivar',
  },
  // Shifts
  shift: {
    startDate: 'Fecha Inicio',
    endDate: 'Fecha Término',
    startTime: 'Hora de inicio',
    endTime: 'Hora de término',
    patient: 'Paciente',
    caregiver: 'Profesional',
    caregiverCost: 'Pago a Profesional (líquido)',
    price: 'Precio',
    caregiverCostPerHour: 'Pago a Profesional por hora (líquido)',
    pricePerHour: 'Precio por hora',
  },
  shiftBatchCreateForm: {
    fieldRequired: 'Campo requerido',
    title: 'Agregar Turnos',
    submit: 'Crear Turnos',
    stepOneTitle: 'Paciente y Profesionales',
    stepOneSubtitle: 'Selecciona el paciente, los profesionales y la fecha de inicio',
    stepTwoTitle: 'Tipo de Turno',
    stepTwoSubtitle: 'Selecciona el tipo de turno',
    stepThreeTitle: 'Precios y Costos',
    stepThreeSubtitle: 'Ingresa los precios y costos',
    caregiverWeekShiftCost: 'Turno Semana',
    caregiverWeekendShiftCost: 'Turno Fin de Semana',
    weekShiftPrice: 'Turno Semana',
    weekendShiftPrice: 'Turno Fin de Semana',
    caregiverWeekShiftCostPerHour: 'Hora Semana',
    caregiverWeekendShiftCostPerHour: 'Hora Fin de Semana',
    weekShiftPricePerHour: 'Hora Semana',
    weekendShiftPricePerHour: 'Hora Fin de Semana',
    dayShift: 'Turno Día (8:00 - 20:00)',
    nightShift: 'Turno Noche (20:00 - 8:00)',
    caregivers: 'Profesionales',
    previous: 'Anterior',
    next: 'Continuar',
    caregiverCostTitle: 'Pago a Profesional (líquido)',
    priceTitle: 'Cobro al Paciente',
    withTaxBillTitle: '¿Con boleta de honorarios?',
    yes: 'Sí',
    no: 'No',
    twentyFourHoursInfo: `Los turnos que selecciones comienzan a partir de las {startTime} horas
      del día seleccionado y terminan a las {endTime} del día siguiente.`,
  },
  shiftRecurrentCreateForm: {
    fieldRequired: 'Campo requerido',
    fieldPositive: 'Debe ser un número positivo',
    title: 'Crear Turnos',
    submit: 'Crear Turnos',
    shiftTypeTitle: 'Tipo de Turno',
    shiftTypeDay: 'Día (8:00 a 20:00)',
    shiftTypeNight: 'Noche (20:00 a 8:00)',
    shiftType24: '24 horas',
    shiftTypeCustom: 'Personalizado',
    recurrenceType: 'Recurrencia',
    recurrenceInterval: 'Repetir',
    recurrenceDays: 'Días de la semana',
    previous: 'Anterior',
    next: 'Continuar',
  },
  shiftForm: {
    fieldRequired: 'Campo requerido',
    editTitle: 'Editar Turno',
    newTitle: 'Crear Turno',
    editSubmit: 'Guardar Cambios',
    newSubmit: 'Crear Turno',
    shiftTypeTitle: 'Tipo de Turno',
    shiftTypeDay: 'Día (8:00 a 20:00)',
    shiftTypeNight: 'Noche (20:00 a 8:00)',
    shiftType24: '24 horas',
    shiftType24Info: 'Selecciona el horario de inicio y término del turno.',
    shiftTypeCustom: 'Personalizado',
    shiftTypeCustomStartInfo: 'Selecciona el horario de inicio del turno.',
    shiftTypeCustomEndInfo: 'Selecciona el horario de término del turno.',
  },
  shiftDestroyForm: {
    title: 'Eliminar Turno',
    submit: 'Eliminar',
    confirmation: '¿Estás seguro que deseas eliminar este turno?',
  },
  shiftBatchDestroyForm: {
    title: 'Detener servicio',
    lastDate: 'Última fecha activo',
    explanation: `Selecciona la última fecha para la que el servicio estará activo.
    Todos los turnos futuros a partir de esa fecha serán eliminados.`,
    confirmation: '¿Estás seguro que deseas eliminar todos los turnos del paciente a partir de esa fecha?',
    submit: 'Sí, Eliminar',
    notFoundError: 'No hay turnos futuros a partir de esa fecha para eliminar.',
  },
  // Service Prices
  servicePriceForm: {
    fieldRequired: 'Campo requerido',
    fieldPositive: 'Debe ser un número mayor a 0',
    priceAlreadyExists: 'El precio para este servicio ya está definido.',
    withTaxBillTitle: '¿Con boleta de honorarios?',
    yes: 'Sí',
    no: 'No',
  },
  // User Session
  login: {
    passwordRequired: 'Contraseña requerida',
    emailRequired: 'Correo electrónico requerido',
    title: 'Iniciar sesión',
    subtitle: 'Ingresa tu correo electrónico y contraseña para entrar a tu cuenta.',
    email: 'Correo electrónico',
    password: 'Contraseña',
    submit: 'Iniciar sesión',
    noAccount: 'No tengo cuenta',
    forgotPassword: 'Olvidé mi contraseña',
    passwordUpdated: 'Contraseña actualizada correctamente',
    emailInvalid: 'Correo electrónico no es válido',
    passwordMinLength: 'La contraseña debe tener al menos 8 caracteres',
    accountUnlocked: 'Tu cuenta ha sido desbloqueada correctamente.',
  },
  editPassword: {
    passwordRequired: 'Contraseña requerida',
    passwordConfirmationRequired: 'La confirmación de la contraseña es requerida',
    passwordConfirmationMismatch: 'Las contraseñas no coinciden',
    title: 'Cambiar contraseña',
    subtitle: 'Ingresa la nueva contraseña para cambiarla.',
    passwordMinLength: 'La contraseña debe tener al menos 8 caracteres',
    password: 'Contraseña',
    passwordConfirmation: 'Confirmar contraseña',
    submit: 'Cambiar contraseña',
    backToLogin: 'Volver al inicio de sesión',
  },
  recoverPassword: {
    emailInvalid: 'Correo electrónico no es válido',
    emailRequired: 'Correo electrónico requerido',
    title: 'Recuperar contraseña',
    subtitle: 'Ingresa tu correo electrónico para recibir las instrucciones de recuperación.',
    email: 'Correo electrónico',
    submit: 'Enviar instrucciones',
    backToLogin: 'Volver al inicio de sesión',
  },
  register: {
    title: 'Crea tu cuenta',
    subtitle: 'Ingresa los datos para crear tu cuenta',
    emailRequired: 'Correo electrónico requerido',
    passwordRequired: 'Contraseña requerida',
    passwordConfirmationRequired: 'La confirmación de la contraseña es requerida',
    passwordConfirmationMismatch: 'Las contraseñas no coinciden',
    email: 'Correo electrónico',
    password: 'Contraseña',
    passwordConfirmation: 'Confirmar contraseña',
    submit: 'Crear cuenta',
    hasAccount: 'Ya tengo cuenta',
  },
  user: {
    signOutError: 'Hubo un error al cerrar sesión, inténtelo nuevamente.',
  },
  userSession: {
    defaultError: 'Hubo un error inesperado, inténtalo nuevamente.',
  },
};
